/* Hacks reset */

/*
For some reason, it seems the react-select import is mashing the chakra styles
If i remove the react select this is not needed. The is the spinner on the button
during a submission. Idk what the cause is yet, but this hack covers it up.
*/
.chakra-button__spinner .chakra-spinner span {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
}


@keyframes pulse {
  0% {
    transform: scale(1.2)
  }
  50% {
    transform: scale3d(1.05, 1.05, 1.05)
  }
  to {
    transform: scale(1.2)
  }
}

@keyframes spin {
  100% {
      transform:rotate(-360deg);
  }
}